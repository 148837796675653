import React, { forwardRef, useRef, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import classNames from 'classnames';

import useDidUpdateEffect from '@/c/hooks/useDidUpdateEffect';
import showIf from '@/c/utils/hidden';

interface PasswordFieldProps {
    id?: string;
    name?: string;
    label?: string;
    value?: string;
    errorText?: string;
    autocomplete?: string;
    onChange: (value: string) => void;
}

const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>((p, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [value, setValue] = useState(p.value || '');
    const passwordRef = useRef<HTMLInputElement>(null);

    useDidUpdateEffect(() => {
        if (passwordRef.current) {
            passwordRef.current.focus();
        } else if (ref) {
            // @ts-ignore
            ref.current && ref.current.focus();
        }
    }, [isVisible]);

    const handleClickShowPassword = () => {
        setIsVisible(!isVisible);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl>
            {p.label && (
                <InputLabel variant={'standard'} htmlFor={p.id}>
                    {p.label}
                </InputLabel>
            )}
            <Input
                id={p.id}
                name={p.name}
                type={isVisible ? 'text' : 'password'}
                onChange={(event) => {
                    setValue(event.target.value);
                    p.onChange(event.target.value);
                }}
                value={value}
                error={p.errorText !== undefined}
                autoComplete={p.autocomplete}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="large"
                        >
                            {isVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <p className={classNames('val-error', showIf(p.errorText !== undefined))}>{p.errorText}</p>
        </FormControl>
    );
});
PasswordField.displayName = 'PasswordField';
export default PasswordField;
