import React, { useState } from 'react';

import { Button, FormControl, Input, InputLabel } from '@mui/material';

import PasswordField from '@/c/components/molecules/PasswordField/PasswordField';

import { Wrapper } from './LoginPage.style';

export interface Credentials {
    email: string;
    password: string;
}

interface Props {
    onSubmit: (c: Credentials) => void;
}

const ri18n = {
    form: {
        email: {
            label: 'Email',
        },
        password: {
            label: 'Password',
        },
    },
    loginBtn: 'Login',
};

export default function LoginPage(p: Props): React.ReactElement {
    const [value, setValue] = useState<Credentials>({ email: '', password: '' });

    const setField = (key: keyof Credentials, v: Credentials[keyof Credentials]) => {
        setValue({
            ...value,
            [key]: v,
        });
    };

    return (
        <Wrapper>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    p.onSubmit(value);
                }}
            >
                <div
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel variant={'standard'} htmlFor="name">
                            {ri18n.form.email.label}
                        </InputLabel>
                        <Input
                            id="email"
                            autoComplete={'username email'}
                            required={true}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                setField('email', event.target.value as string)
                            }
                        />
                    </FormControl>
                </div>
                <div
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    <PasswordField
                        id={'password'}
                        name={'password'}
                        label={ri18n.form.password.label}
                        autocomplete="new-password"
                        onChange={(value) => setField('password', value)}
                    />
                </div>
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Button type="submit" variant="contained" color="primary">
                        {ri18n.loginBtn}
                    </Button>
                </div>
            </form>
        </Wrapper>
    );
}
