import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Will run the provided function only after mount (after first render)
 */
export default function useDidUpdateEffect(func: EffectCallback, deps?: DependencyList): void {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            return func();
        }
    }, deps);
}
