import React, { useState } from 'react';

import { Alert } from '@mui/lab';
import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { SSRConfig, withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import LoginPage, { Credentials } from '@/c/components/pages/LoginPage/LoginPage';
import { DEFAULT_LOCALE } from '@/c/constants/locales';
import { setSession } from '@/c/http/api';
import { croutes, resolveRoute } from '@/c/routes';
import { scopedT } from '@/c/translations/utils';

import { NextPageProps } from '../types/NextPageProps';
import { ApiLoginResponse } from './api/login';

const T_NAMESPACE = 'login-page';

const Page = ({ t }: NextPageProps) => {
    const nt = scopedT(t, T_NAMESPACE);
    const authT = scopedT(t, 'auth');

    const [errorMsg, setErrorMsg] = useState<string>();

    const onSubmit = async (c: Credentials) => {
        await fetch('/wapi/v1/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(c),
        }).then(async (res) => {
            if (res.status === 200) {
                const loginResponse = await res.json();
                setSession(loginResponse as ApiLoginResponse);
                const qp = new URLSearchParams(window.location.search);
                window.location.href = qp.has('returnUrl') ? qp.get('returnUrl')! : resolveRoute(croutes.INDEX);
            } else if (res.status === 401) {
                setErrorMsg(authT('invalidCredentials'));
            } else {
                setErrorMsg(t('server.internalError'));
            }
        });
    };

    return (
        <>
            <Head>
                <title>{nt('pageTitle')}</title>
            </Head>
            {errorMsg && (
                <Alert
                    severity="error"
                    style={{
                        marginBottom: '1rem',
                    }}
                >
                    {errorMsg}
                </Alert>
            )}
            <LoginPage onSubmit={onSubmit} />
        </>
    );
};

const tNamespaces = ['common', 'header', 'footer', 'auth', T_NAMESPACE];
export async function getStaticProps({ locale }: GetStaticPropsContext): Promise<GetStaticPropsResult<SSRConfig>> {
    return {
        props: {
            ...(await serverSideTranslations(locale || DEFAULT_LOCALE, tNamespaces)),
        },
    };
}

export default withTranslation(tNamespaces)(Page as React.FC);
